@import './reset.css';
@import './vars.pcss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import '../node_modules/@solana/wallet-adapter-react-ui/styles.css';

* {
    font-family: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

html {
    &.light {
        background: var(--theme-light-background);
        color: var(--theme-light-color);
    }

    &.dark {
        background: var(--theme-dark-background);
        color: var(--theme-dark-color);
    }

    & body {
        & .wallet-adapter-modal {
            overflow-y: initial;
        }

        & .wallet-adapter-modal-container {
            justify-content: flex-end;
            align-items: flex-start;
            margin: 16px;
        }
    }
}

body {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

button {
    background: none;
    border: none;
    color: inherit;
}

#app {
    min-height: 100vh;
}
